/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingOverlay from 'components/LoadingOverlay';
import firebaseConfig from 'constants/firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Routes from './Routes';
import GlobalStyle from './StyledApp';
import BaseTheme from './Theme';

const StyledLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
`;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sessionUser, setSessionUser] = useState<any>(null);

  useEffect(() => {
    const load = async () => {
      console.log('init app !');
      // const app = initializeApp(firebaseConfig);
      initializeApp(firebaseConfig);

      const auth = getAuth();

      // triggers initially once even without actual change
      onAuthStateChanged(auth, (user) => {
        console.log('AUTH CHANGE ! => ', user);
        if (user) {
          setSessionUser(user);
        } else {
          setSessionUser(null);
        }
        setIsLoading(false);
      });
    };

    load();
  }, []);

  return isLoading ? (
    <ThemeProvider theme={BaseTheme}>
      <GlobalStyle />
      <StyledLoadingContainer>
        <LoadingOverlay show={true} />
      </StyledLoadingContainer>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={BaseTheme}>
      <GlobalStyle />
      <Routes user={sessionUser} />
    </ThemeProvider>
  );
};

export default App;
