/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Bullet from 'components/Bullet';
import Footer from 'components/Footer';
import Headline from 'components/Headline';
import ImageCacheItem from 'components/ImageCacheItem';
import ImageGridItem from 'components/ImageGridItem';
import LoadingOverlay from 'components/LoadingOverlay';
import LoadingScreenOverlay from 'components/LoadingScreenOverlay';
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from 'styled-components/macro';
import ImageEntryType from 'types/imageEntryType';

const StyledContainer = styled.div`
  padding: 10px;
`;

const StyledImageGridContainer = styled.div`
  padding-top: 40px;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const StyledBulletFilter = styled.div`
  margin-top: 40px;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;

  gap: 5px;
`;

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState<Array<string>>([]);
  const [filterTags, setFilterTags] = useState<Array<string>>([]);
  const [activeFilter, setActiveFilter] = useState<Array<string>>([]);
  const [imageDocs, setImageDocs] = useState<Array<ImageEntryType>>([]);
  const [filteredDocs, setFilteredDocs] = useState<Array<ImageEntryType>>([]);

  useEffect(() => {
    if (activeFilter.length > 0) {
      const filtered: Array<ImageEntryType> = imageDocs.filter((imageDoc) => {
        let hasFilterTag = false;
        for (let i = 0; i < activeFilter.length; i += 1) {
          const filter = activeFilter[i];
          if (imageDoc.tags.includes(filter)) {
            hasFilterTag = true;
          }
        }

        if (hasFilterTag) {
          return imageDoc;
        }

        return null;
      });

      setFilteredDocs(filtered);
    } else {
      setFilteredDocs([...imageDocs]);
    }
  }, [activeFilter, imageDocs]);

  useEffect(() => {
    if (loadedImages.length >= imageDocs.length) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [loadedImages, imageDocs]);

  useEffect(() => {
    setLoading(true);
    const db = getFirestore();
    const q = query(collection(db, 'image'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs: Array<ImageEntryType> = [];
      let docTags: Array<string> = [];

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const imageEntryData: ImageEntryType = {
          id: doc.id,
          ref: doc.ref,
          path: docData.path,
          thumbPath: docData.thumbPath,
          tags: docData.tags,
          created: docData.created,
          description: docData.description,
          sortOrder: docData.sortOrder,
          url: docData.url,
        };

        docTags = [...docTags, ...docData.tags];
        docs.push(imageEntryData);
      });

      const uniqueDocTags = docTags.filter((v, i, a) => a.indexOf(v) === i);

      setImageDocs(docs);
      setFilterTags(uniqueDocTags);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const addFilter = (filterName: string) => {
    const update: string[] = [...activeFilter];
    update.push(filterName);
    setActiveFilter(update);
  };

  const removeFilter = (filterName: string) => {
    const update = activeFilter.filter((item) => item !== filterName);
    setActiveFilter(update);
  };

  const containsFilter = (filterName: string) =>
    activeFilter.findIndex((item) => item === filterName) >= 0;

  const handleFilterUpdate = (filterName: string) => {
    const contains = containsFilter(filterName);

    if (contains) {
      removeFilter(filterName);
    } else {
      addFilter(filterName);
    }
  };

  const addToHasLoaded = (id: string) => {
    const loadedUpdate = [...loadedImages];
    loadedUpdate.push(id);
    setLoadedImages(loadedUpdate);
  };

  return (
    <>
      <LoadingOverlay show={loading} />
      <StyledContainer>
        <Headline />
        <StyledBulletFilter>
          {filterTags.map((filter) => (
            <Bullet
              key={filter}
              active={containsFilter(filter)}
              text={filter}
              onClick={() => handleFilterUpdate(filter)}
            />
          ))}
        </StyledBulletFilter>
        <StyledImageGridContainer>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1280: 4, 1980: 5 }}>
            <Masonry gutter="8">
              {filteredDocs.map((imageDoc) => (
                <ImageGridItem key={imageDoc.id} data={imageDoc} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </StyledImageGridContainer>
        <div style={{ visibility: 'hidden', position: 'fixed', left: '-10000px', top: '-10000px' }}>
          {imageDocs.map((imageDoc) => (
            <ImageCacheItem
              key={imageDoc.id}
              data={imageDoc}
              hasLoaded={(id: string) => addToHasLoaded(id)}
            />
          ))}
        </div>
      </StyledContainer>
      <Footer />
    </>
  );
};

export default Home;
