/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ImageEntryType from 'types/imageEntryType';

type ImageGridItemPropTypes = {
  data: ImageEntryType;
  hasLoaded: Function;
};

const StyledItem = styled.div`
  visibility: hidden;
  img {
  }
`;

const ImageCacheItem = ({ data, hasLoaded }: ImageGridItemPropTypes) => {
  const [imgSrc, setImgSrc] = useState<string>();
  const [imgThumbSrc, setImgThumbSrc] = useState<string>();

  useEffect(() => {
    const load = async () => {
      // load thumb img src
      // alternatively use original
      setImgSrc(data.url);

      try {
        const storage = getStorage();
        const path = data.thumbPath;
        const storageRef = ref(storage, path);
        const url = await getDownloadURL(storageRef);

        setImgThumbSrc(url);
      } catch (e) {
        console.log('error getting download url => ', e);
        return false;
      }
    };

    load();
  }, [data]);

  return (
    <StyledItem>
      <img
        src={imgThumbSrc !== undefined ? imgThumbSrc : imgSrc}
        alt={data.id}
        onLoad={() => hasLoaded(data.id)}
      />
    </StyledItem>
  );
};

export default ImageCacheItem;
