import React from 'react';
import styled from 'styled-components/macro';

type HeadlineProps = {
  asLink?: boolean;
};

const StyledLink = styled.a`
  text-decoration: none;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: default;
  text-decoration: none;

  a,
  a:focus,
  a:visited {
    text-decoration: none;
    text-align: center;
    color: inherit;
  }

  h1 {
    margin: 10px 0 5px 0;
    font-family: 'Josefin Slab';
    font-style: normal;
    font-weight: 200;
    font-size: 60px;
  }

  h3 {
    margin: 5px;
    padding: 0;
    font-family: 'Josefin Slab';
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;

const Headline = ({ asLink }: HeadlineProps) => {
  if (asLink) {
    return (
      <StyledTextContainer>
        <StyledLink href="/">
          <h1>T. Wultschner</h1>
          <h3>PHOTOGRAPHY</h3>
        </StyledLink>
      </StyledTextContainer>
    );
  }
  return (
    <StyledTextContainer>
      <h1>T. Wultschner</h1>
      <h3>PHOTOGRAPHY</h3>
    </StyledTextContainer>
  );
};

const defaultProps = {
  asLink: false,
};

Headline.defaultProps = defaultProps;

export default Headline;
