/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Bullet from 'components/Bullet';
import { deleteDoc, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ImageEntryType from 'types/imageEntryType';
import {
  faPlus as AddIcon,
  faTrash as DeleteIcon,
  faPencil as EditIcon,
  faSave as SaveIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from './Popup';

type AdminListItemPropTypes = {
  data: ImageEntryType;
};

const StyledListItem = styled.div`
  border: 1px solid #c7c7c7;
  background: #fafafa;
  display: flex;
  border-radius: 8px;
  width: 700px;
`;

const StyledBulletList = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  min-width: 440px;

  button {
    background-color: ${(p) => p.theme.colors.darkBlue};
    border: none;
    cursor: pointer;
    border-radius: 20px;
    width: 30px;
    transition: 0.6s ease all;

    & > * {
      color: white;
    }

    &:hover {
      & > * {
        opacity: 0.8;
      }
      opacity: 0.8;
    }
  }
`;

const StyledImageInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  textarea {
    margin-top: 5px;
    padding: 6px;
    width: 95%;
    border: none;
    text-indent: 5px;
    resize: none;
    height: 46px;
  }

  button {
    border: none;
    background-color: ${(p) => p.theme.colors.darkBlue};
    color: #fff;
    border-radius: 8px;
    padding-left: 6px;
    padding-right: 6px;
    width: 30px;

    height: 30px;
    cursor: pointer;
    padding: 5px;
    &:hover {
      & > * {
        opacity: 0.8;
      }
      opacity: 0.8;
    }
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  padding-left: 25px;
  padding-right: 25px;
  gap: 20px;
`;

const StyledDelete = styled.div`
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    width: 30px;
    transition: 0.6s ease all;

    & > * {
      color: red;
    }

    &:hover {
      & > * {
        opacity: 0.8;
      }
      opacity: 0.8;
    }
  }
`;

const StyledImage = styled.div`
  width: 175px;
  height: 175px;
  min-width: 175px;
  max-width: 175px;
  min-height: 175px;
  max-height: 175px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    /*
    width: 100px;
    height: 100px;
    */
  }
`;
const StyledAddButton = styled.div`
  width: 130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background-color: ${(p) => p.theme.colors.darkBlue};
  border-radius: 8px;
  input {
    text-indent: 8px;
    background-color: ${(p) => p.theme.colors.darkBlue};
    border-radius: 8px;
    border: none;
    width: calc(100% - 30px);
    height: 30px;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }

  button {
    width: 30px;
    height: 30px;
  }
`;

const AdminListItem = ({ data }: AdminListItemPropTypes) => {
  const [editInfo, setEditInfo] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>();
  const [imgThumbSrc, setImgThumbSrc] = useState<string>();
  const [tags, setTags] = useState<Array<string>>([]);
  const [description, setDescription] = useState<string>();
  const [newTag, setNewTag] = useState<string>('');

  useEffect(() => {
    const load = async () => {
      // load thumb img src
      // alternatively use original
      setDescription(data.description);
      setTags(data.tags);
      setImgSrc(data.url);

      try {
        const storage = getStorage();
        const path = data.thumbPath;
        const storageRef = ref(storage, path);
        const url = await getDownloadURL(storageRef);

        setImgThumbSrc(url);
      } catch (e) {
        console.log('error getting download url => ', e);
        return false;
      }
    };

    load();
  }, [data]);

  const saveInfo = async () => {
    setEditInfo(false);

    // update firebase entry with new description// Set the "capital" field of the city 'DC'
    // await
    updateDoc(data.ref, {
      description,
    });
  };

  const addTag = () => {
    const tagUpdate = [...tags];
    tagUpdate.push(newTag);
    setTags(tagUpdate);
    setNewTag('');

    // update firebase entry
    updateDoc(data.ref, {
      tags: tagUpdate,
    });
  };

  const removeTag = (tagToRemove: string) => {
    // filter out tag by name
    const tagUpdate = [...tags].filter((tag) => tag !== tagToRemove);
    setTags(tagUpdate);

    // update firebase entry
    updateDoc(data.ref, {
      tags: tagUpdate,
    });
  };

  const deleteEntry = async () => {
    const db = getFirestore();
    const storage = getStorage();

    // delete storage elements
    try {
      const storageRef = ref(storage, data.path);
      await deleteObject(storageRef);
    } catch (e) {
      console.log(e);
    }

    // delete storage elements
    try {
      const storageRef = ref(storage, data.thumbPath);
      await deleteObject(storageRef);
    } catch (e) {
      console.log(e);
    }

    // onSnapshot will remove the current instance itself
    setShowDeletePopup(false);

    // delete entry from firebase
    // await
    deleteDoc(doc(db, 'image', data.id));

    return true;
  };

  const handleTagKeyDown = (e: any) => {
    console.log(e.key);
    if (e.key === 'Enter') {
      addTag();
    }
  };

  return (
    <StyledListItem>
      <StyledImage>
        <img src={imgThumbSrc !== undefined ? imgThumbSrc : imgSrc} alt={description} />
      </StyledImage>
      <StyledInfo>
        <StyledImageInfo>
          {editInfo ? (
            <>
              <textarea value={description} onChange={(e) => setDescription(e.target.value)} />

              <button type="button" onClick={() => saveInfo()}>
                <FontAwesomeIcon icon={SaveIcon} />
              </button>
            </>
          ) : (
            <>
              <p>
                {description !== undefined && description !== '' ? (
                  description
                ) : (
                  <i>- Keine Beschreibung -</i>
                )}
              </p>
              <button type="button" onClick={() => setEditInfo(true)}>
                <FontAwesomeIcon icon={EditIcon} />
              </button>
            </>
          )}
        </StyledImageInfo>
        <StyledBulletList>
          {tags.map((tag) => (
            <Bullet key={tag} text={tag} onDeleteClick={() => removeTag(tag)} />
          ))}

          <StyledAddButton>
            <input
              type="text"
              name="newTag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="new tag ..."
              onKeyDown={(e) => handleTagKeyDown(e)}
            />
            <button type="button" onClick={() => addTag()}>
              <FontAwesomeIcon icon={AddIcon} />
            </button>
          </StyledAddButton>
        </StyledBulletList>
      </StyledInfo>

      <StyledDelete>
        <button type="button" onClick={() => setShowDeletePopup(true)}>
          <FontAwesomeIcon icon={DeleteIcon} />
        </button>
      </StyledDelete>
      <Popup
        show={showDeletePopup}
        headline="Eintrag Löschen ?"
        text="Diesen Eintrag wirklich löschen ?"
        onYes={() => deleteEntry()}
        onNo={() => setShowDeletePopup(false)}
      />
    </StyledListItem>
  );
};

export default AdminListItem;
