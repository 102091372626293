import React from 'react';
import styled from 'styled-components/macro';
import { faCircleMinus as deleteIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type BulletProps = {
  active?: boolean;
  text: string;
  onClick?: Function;
  onDeleteClick?: Function;
};

type StyledBulletProps = {
  active?: boolean;
  onClick?: Function;
};

type StyledDeleteProps = {
  onClick?: Function;
};

const StyledBullet = styled.div<StyledBulletProps>`
  display: flex;
  user-select: none;
  padding: 5px 15px;

  flex-direction: row;
  align-items: center;
  align-content: center;
  text-align: center;
  gap: 10px;
  justify-content: space-between;

  background-color: ${(p) => (p.active ? p.theme.colors.blue : p.theme.colors.darkBlue)};
  border-radius: 20px;

  cursor: ${(p) => (p.onClick !== null ? 'pointer' : 'default')};

  transition: 0.6s ease all;

  &:hover {
    opacity: 0.8;
  }

  & > * {
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
  }
`;

const StyledDelete = styled.div<StyledDeleteProps>`
  z-index: 2;
  & > * {
    transition: 0.3s ease all;
  }

  &:hover {
    & > * {
      color: red;
    }
  }
`;

const Bullet = ({ active = false, text, onClick, onDeleteClick }: BulletProps) => (
  <StyledBullet active={active} onClick={() => (onClick ? onClick(text) : false)}>
    <span>{text}</span>
    {onDeleteClick ? (
      <StyledDelete onClick={() => (onDeleteClick ? onDeleteClick(text) : false)}>
        <FontAwesomeIcon icon={deleteIcon} />
      </StyledDelete>
    ) : null}
  </StyledBullet>
);

const defaultProps = {
  active: false,
  onClick: null,
  onDeleteClick: null,
};

Bullet.defaultProps = defaultProps;

export default Bullet;
