/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Headline from 'components/Headline';
import UploadItem from 'components/UploadItem';
import { addDoc, collection, getFirestore, serverTimestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

type UploadedType = {
  url: string;
  path: string;
  error: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  &:disabled {
    background-color: #001722;
    border: 1px solid #001722;
    color: #979797;
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }

  background-color: ${(p) => p.theme.colors.darkBlue};
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.darkBlue};
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledListContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1024px;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 20px;
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledListItem = styled.div`
  border: 1px solid #c7c7c7;
  background: #fafafa;
  display: flex;
  border-radius: 8px;
  width: 700px;
`;

const StyledUploadButton = styled.div`
  padding: 25px;
  border: 1px solid #c7c7c7;
  background: #fafafa;
  display: flex;
  border-radius: 8px;
  width: 400px;
  margin-bottom: 20px;
`;

const AdminUpload = () => {
  const history = useHistory();

  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedType>>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const onFileChange = (event: any) => {
    // read the image file as a data URL.
    if (event.target.files && event.target.files.length > 0) {
      const filesToUpload = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        const file = event.target.files[i];
        // @TODO stop non images and to large images here
        filesToUpload.push(file);
      }

      setSelectedFiles(filesToUpload);
    } else {
      setSelectedFiles([]);
    }
    // const { type, size } = file;
    // if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
  };

  const createImageEntry = async (path: string, thumbPath: string, url: string) => {
    // create firebase entry for url
    const uploadedUpdate = [...uploadedFiles];
    const entry = {
      url,
      path,
      error: false,
    };
    uploadedUpdate.push(entry);

    const db = getFirestore();

    // create firebase image entry
    const firebaseEntry = {
      path,
      thumbPath,
      url,
      tags: [],
      description: '',
      sortOrder: 1,
      created: serverTimestamp(),
    };

    // Add a new document in collection "cities"
    const docRef = await addDoc(collection(db, 'image'), firebaseEntry);

    console.log(firebaseEntry);

    console.log(docRef);

    if (uploadedUpdate.length === selectedFiles.length) {
      history.push('/admin/list');
    }
  };

  const onUploadError = () => {
    setHasError(true);
    const uploadedUpdate = [...uploadedFiles];
    const entry = {
      url: '',
      path: '',
      error: true,
    };
    uploadedUpdate.push(entry);

    if (uploadedUpdate.length === selectedFiles.length) {
      history.push('/admin/list');
    }
  };

  return (
    <StyledContainer>
      <Headline asLink />
      <StyledListContainer>
        <div>
          <StyledButton type="button" onClick={() => history.push('/admin/list')}>
            Bilder Liste anschauen
          </StyledButton>
        </div>

        <StyledUploadButton>
          <div>
            <p>Hier klicken und Dateien auswählen</p>
            <input
              id="upload-images"
              name="upload-images"
              type="file"
              onChange={onFileChange}
              accept="image/png, image/jpeg"
              multiple
            />
          </div>
        </StyledUploadButton>
        <StyledList>
          {selectedFiles.map((file) => (
            <StyledListItem key={file.name}>
              <UploadItem
                file={file}
                onComplete={(path: string, thumbPath: string, url: string) =>
                  createImageEntry(path, thumbPath, url)
                }
                onError={() => onUploadError()}
              />
            </StyledListItem>
          ))}
        </StyledList>
      </StyledListContainer>
    </StyledContainer>
  );
};

export default AdminUpload;
