import { createGlobalStyle } from 'styled-components';
import JosefinSlab200Woff from './assets/fonts/josefin-slab-v18-latin-200.woff';
import JosefinSlab200Woff2 from './assets/fonts/josefin-slab-v18-latin-200.woff2';
import Lato100Woff from './assets/fonts/lato-v22-latin-100.woff';
import Lato100Woff2 from './assets/fonts/lato-v22-latin-100.woff2';
import Lato300Woff from './assets/fonts/lato-v22-latin-300.woff';
import Lato300Woff2 from './assets/fonts/lato-v22-latin-300.woff2';
import Lato900Woff from './assets/fonts/lato-v22-latin-900.woff';
import Lato900Woff2 from './assets/fonts/lato-v22-latin-900.woff2';
import LatoItalicWoff from './assets/fonts/lato-v22-latin-italic.woff';
import LatoItalicWoff2 from './assets/fonts/lato-v22-latin-italic.woff2';
import LatoRegularWoff from './assets/fonts/lato-v22-latin-regular.woff';
import LatoRegularWoff2 from './assets/fonts/lato-v22-latin-regular.woff2';

const GlobalStyle = createGlobalStyle`
  /* josefin-slab-200 - latin */
  @font-face {
    font-family: 'Josefin Slab';
    font-style: normal;
    font-weight: 200;
    src: local(''),
        url(${JosefinSlab200Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${JosefinSlab200Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* lato-100 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: local(''),
        url(${Lato100Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${Lato100Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* lato-300 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url(${Lato300Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${Lato300Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url(${LatoRegularWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${LatoRegularWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* lato-italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url(${LatoItalicWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${LatoItalicWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: local(''),
        url(${Lato900Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${Lato900Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  *,
  *:before,
  *:after {
      box-sizing: border-box;
  }

  html {
    line-height: inherit;
    -ms-text-size-adjust: inherit;
    -webkit-text-size-adjust: inherit;
  }

  body {
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
  }

  html,
  body {
    background: ${(props) => props.theme.colors.background};
    height: 100%;
    color: #000;
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  #diwaso {
    width: 100%;
    height: 100%;
  }

  ol, ul {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table:not(.boiler-book) {
    width: 100%;
    border-collapse: collapse;

    tr:nth-child(even) {
      background-color: #f5f5f5;
    }

    th.center, td.center {
      text-align: center !important;
    }
  }

  table:not(.boiler-book) th {
    background-color: #fff;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderTable};
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #051014;
  }

  table:not(.boiler-book) td {
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    color: #051014;
  }

  table:not(.boiler-book) tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  table:not(.boiler-book) tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  table:not(.boiler-book) tr:first-child th:first-child,
  table:not(.boiler-book) tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }

  table:not(.boiler-book) tr:first-child th:last-child,
  table:not(.boiler-book) tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  table.table-panel {
    td {
      padding: 10px 0;

      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }

  table.table-panel tr:nth-child(even) {
    background-color: #fff;
  }

`;

export default GlobalStyle;
