// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCELcO9Ln2iQCw1nXlgbVzapM_IAUbD3ec',
  authDomain: 'tw-photo-9ecb8.firebaseapp.com',
  projectId: 'tw-photo-9ecb8',
  storageBucket: 'tw-photo-9ecb8.appspot.com',
  messagingSenderId: '798860242259',
  appId: '1:798860242259:web:43b0483d855997a4de85a6',
};

export default firebaseConfig;
