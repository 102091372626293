/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import AdminList from './pages/AdminList';
import AdminLogin from './pages/AdminLogin';
import AdminUpload from './pages/AdminUpload';
import DataPrivacy from './pages/DataPrivacy';
import Home from './pages/Home';
import Imprint from './pages/Imprint';
import NoMatch from './pages/NoMatch';

type RouteProps = {
  user: any;
};

const StyledMain = styled.div``;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ user, children, ...rest }: any) => (
  <Route
    {...rest}
    render={({ location }) =>
      user !== null ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/admin/login',
            state: { from: location },
          }}
        />
      )
    }
  />
);

const Routes = ({ user }: RouteProps) => (
  <Router>
    <StyledMain>
      <Switch>
        <Route path="/" exact render={(props: any) => <Home {...props} />} />

        <Route path="/impressum" exact render={(props: any) => <Imprint {...props} />} />
        <Route path="/datenschutz" exact render={(props: any) => <DataPrivacy {...props} />} />

        <Route path="/admin/login" exact render={(props: any) => <AdminLogin {...props} />} />

        <PrivateRoute path="/admin/list" exact user={user}>
          <AdminList />
        </PrivateRoute>

        <PrivateRoute path="/admin/upload" exact user={user}>
          <AdminUpload />
        </PrivateRoute>

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </StyledMain>
  </Router>
);

Routes.propTypes = {};

export default Routes;
