/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Footer from 'components/Footer';
import Headline from 'components/Headline';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  padding: 60px;
  max-width: 600px;
  align-self: center;
  margin: 0 auto;

  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: 500;
  }

  small {
    font-size: 9px;
  }
`;

const DataPrivacy = () => (
  <>
    <Headline asLink />
    <StyledContainer>
      <h1>Datenschutzerklärung</h1>
      <small>Stand: 23. März 2022</small>
      <h2 id="m3">Verantwortlicher</h2>
      <p>Thomas Wultschner</p>
      E-Mail-Adresse:{' '}
      <p>
        <a href="https://aemail.com/488J">thomas.wultschner@web.de</a>
      </p>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer
        Verarbeitung zusammen und verweist auf die betroffenen Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Inhaltsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-/Kommunikationsdaten.</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Nutzer.</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
      </ul>
      <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
      <p>
        Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
        personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen
        der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
        können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen
        wir Ihnen diese in der Datenschutzerklärung mit.
      </p>
      <ul>
        <li>
          <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die
          Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
          Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
          betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
        </li>
      </ul>
      <p>
        Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale
        Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz
        vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz –
        BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
        Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
        Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten
        Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die
        Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
        Hinblick auf die Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen
        sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der
        einzelnen Bundesländer zur Anwendung gelangen.
      </p>
      <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
        Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke
        der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes
        der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
        organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
        Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den
        Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
        Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
        Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung
        der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten
        bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend
        dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen.
      </p>
      <p>
        SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu
        schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
        Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
      </p>
      <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
      <p>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an
        andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen
        übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten
        können z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
        Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir die
        gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen,
        die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
      </p>
      <h2 id="m24">Datenverarbeitung in Drittländern</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des
        Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
        Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an
        andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
        gesetzlichen Vorgaben.{' '}
      </p>
      <p>
        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher
        Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten
        Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der
        EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen
        Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der
        EU-Kommission:{' '}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
          target="_blank"
          rel="noreferrer"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
        </a>
        ).
      </p>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>
        Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die
        Leistungen von einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw.
        von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken
        können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
        Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch
        nehmen.
      </p>
      <p>
        Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die
        Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und
        der Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um
        die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres
        Onlineangebotes oder von Webseiten getätigten Eingaben.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in
          Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
          Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
          DSGVO).
        </li>
      </ul>
      <p>
        <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Wir selbst (bzw. unser
          Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte
          Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen
          Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer
          URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
          gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden,
          z.B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von
          missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung
          der Server und ihre Stabilität sicherzustellen; <strong>Löschung von Daten:</strong>{' '}
          Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach
          gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
          erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der
          Löschung ausgenommen.
        </li>
      </ul>
    </StyledContainer>
    <Footer />
  </>
);

export default DataPrivacy;
