import React from 'react';
import styled from 'styled-components/macro';

const StyledFooter = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  cursor: default;
  flex-wrap: wrap;
  gap: 10px;

  & > * {
    font-size: 12px;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  div {
    a:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <div>
      <a href="/impressum">Impressum</a>
      <a href="/datenschutz">Datenschutz</a>
    </div>

    <div>
      <span>Website und Bilder &copy; Thomas Wultschner 2022</span>
    </div>

    <div>
      <a href="https://www.instagram.com/thomymyhomy/" target="_blank" rel="noreferrer">
        Instagram
      </a>
      <a href="https://aemail.com/488J">Kontakt</a>
    </div>
  </StyledFooter>
);

export default Footer;
