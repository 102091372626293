/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

type FullsizeImageProps = {
  path: string;
  show: boolean;
  onClose: Function;
};

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  width: 100%;
  height: 100%;
`;

const StyledBackGround = styled.div`
  background-color: ${(props: any) => props.theme.colors.background};
  opacity: 0.9;
  background-color: #dbdbdb;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 991;
`;

const StyledLoadingContainer = styled.div`
  z-index: 992;
  z-index: 992;
  position: absolute;
  align-items: center;
  align-content: center;
  justify-content: center;
  .sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    transform-origin: 100% 100%;
  }

  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  @keyframes sk-foldCubeAngle {
    0%,
    10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%,
    75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%,
    100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
`;

const StyledLoading = styled.div``;

const StyledContainer = styled.div`
  z-index: 995;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;

  img {
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    object-fit: contain;
  }
`;

const FullsizeImage = ({ show, path, onClose }: FullsizeImageProps) => {
  const [imgSrc, setImgSrc] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleEscKey = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', handleEscKey, false);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [show, handleEscKey]);

  useEffect(() => {
    const load = async () => {
      // load thumb img src
      // alternatively use original

      try {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const url = await getDownloadURL(storageRef);

        setImgSrc(url);
      } catch (e) {
        console.log('error getting download url => ', e);
        return false;
      }
    };

    load();
  }, [path]);

  if (!show) {
    return null;
  }

  return (
    <StyledOverlay onClick={() => onClose()}>
      <StyledContainer>
        <img src={imgSrc} alt="fullsize" onLoad={() => setLoading(false)} />
        {loading ? (
          <StyledLoadingContainer>
            <StyledLoading className="sk-folding-cube">
              <div className="sk-cube1 sk-cube" />
              <div className="sk-cube2 sk-cube" />
              <div className="sk-cube4 sk-cube" />
              <div className="sk-cube3 sk-cube" />
            </StyledLoading>
          </StyledLoadingContainer>
        ) : null}
      </StyledContainer>
      <StyledBackGround />
    </StyledOverlay>
  );
};

export default FullsizeImage;
