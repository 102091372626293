import AdminListItem from 'components/AdminListItem';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Headline from 'components/Headline';
import LoadingOverlay from 'components/LoadingOverlay';
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import ImageEntryType from 'types/imageEntryType';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
const StyledButton = styled.button`
  &:disabled {
    background-color: #001722;
    border: 1px solid #001722;
    color: #979797;
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }

  background-color: ${(p) => p.theme.colors.darkBlue};
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.darkBlue};
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 20px;
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AdminList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [imageDocs, setImageDocs] = useState<Array<ImageEntryType>>([]);

  useEffect(() => {
    setLoading(true);
    const db = getFirestore();
    const q = query(collection(db, 'image'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs: Array<ImageEntryType> = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const imageEntryData: ImageEntryType = {
          id: doc.id,
          ref: doc.ref,
          path: docData.path,
          thumbPath: docData.thumbPath,
          tags: docData.tags,
          created: docData.created,
          description: docData.description,
          sortOrder: docData.sortOrder,
          url: docData.url,
        };

        docs.push(imageEntryData);
      });
      setImageDocs(docs);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <StyledContainer>
      <Headline asLink />
      <StyledListContainer>
        <div>
          <StyledButton type="button" onClick={() => history.push('/admin/upload')}>
            Bilder hochladen
          </StyledButton>
        </div>
        <StyledList>
          {imageDocs.map((imageDoc) => (
            <AdminListItem key={imageDoc.id} data={imageDoc} />
          ))}
        </StyledList>
      </StyledListContainer>
      <LoadingOverlay show={loading} />
    </StyledContainer>
  );
};

export default AdminList;
