import React from 'react';
import styled from 'styled-components/macro';

type LoadingBarProps = {
  percent: number;
  activeColor: string;
};

type StyledLoadingBarProps = {
  activeColor: string;
};

const StyledLoading = styled.div<StyledLoadingBarProps>`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  margin-left: 10px;
  margin-right: 20px;

  .loading-background {
    background-color: #b8b8b8;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    width: 100%;
    height: 15px;
  }

  .loading-active {
    transition: 1s all ease;
    position: absolute;
    bottom: 22px;
    left: 0px;
    background-color: ${(p) => p.activeColor};
    border-radius: 6px;
    width: 60%;
    height: 15px;
  }
`;

const LoadingBar = ({ percent = 0, activeColor }: LoadingBarProps) => (
  <StyledLoading activeColor={activeColor}>
    <div className="loading-background" />
    <div className="loading-active" style={{ width: `${percent}%` }} />
  </StyledLoading>
);

export default LoadingBar;
