/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Bullet from 'components/Bullet';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ImageEntryType from 'types/imageEntryType';
import FullsizeImage from './FullsizeImage';

type ImageGridItemPropTypes = {
  data: ImageEntryType;
};

const StyledImageGridItem = styled.div`
  padding: 5px;
  cursor: pointer;
  position: relative;
  transition: all 1s ease;

  &:hover {
    .info {
      opacity: 1;
    }
  }

  & > .info {
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 8px;
    width: calc(100% - 10px);
    border-radius: 0px 0px 6px 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 3px;
    transition: 0.5s ease all;
    font-size: 14px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    // box-shadow: 5px 3px 5px 2px rgb(0 0 0 / 25%);
  }
`;

const ImageGridItem = ({ data }: ImageGridItemPropTypes) => {
  const [showFullscreen, setShowFullscreen] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>();
  const [imgThumbSrc, setImgThumbSrc] = useState<string>();
  const [tags, setTags] = useState<Array<string>>([]);
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    const load = async () => {
      // load thumb img src
      // alternatively use original
      setDescription(data.description);
      setTags(data.tags);
      setImgSrc(data.url);

      try {
        const storage = getStorage();
        const path = data.thumbPath;
        const storageRef = ref(storage, path);
        const url = await getDownloadURL(storageRef);

        setImgThumbSrc(url);
      } catch (e) {
        console.log('error getting download url => ', e);
        return false;
      }
    };

    load();
  }, [data]);

  return (
    <>
      <StyledImageGridItem onClick={() => setShowFullscreen(true)}>
        <div>
          <img src={imgThumbSrc !== undefined ? imgThumbSrc : imgSrc} alt={description} />
        </div>

        {description !== undefined && description !== null && description !== '' ? (
          <div className="info">
            <p>{description}</p>
          </div>
        ) : null}
      </StyledImageGridItem>
      <FullsizeImage
        show={showFullscreen}
        path={data.path}
        onClose={() => {
          console.log('close ?');
          setShowFullscreen(false);
        }}
      />
    </>
  );
};

export default ImageGridItem;
