import colors from 'constants/colors';

const BaseTheme = {
  colors,
  button: {
    background: '#163253',
    color: '#ffffff',
  },
  buttonHover: {
    background: '#051014',
    color: '#ffffff',
  },
  breakpoints: {
    small: '480px',
    medium: '960px',
    betweenMedium: '1025px',
    mediumLarge: '1280px',
    large: '1440px',
    xlarge: '2300px',
  },
};

export default BaseTheme;
