/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import React from 'react';
import styled from 'styled-components/macro';

const StyledBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(143, 143, 143, 0.7);
  z-index: 99;
`;
const StyledPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContent = styled.div`
  z-index: 101;
  position: relative;
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #cfcfcf;
  .popup-head {
    font-weight: bold;
    font-size: 18px;
  }
`;
const StyledButtonLayer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;

  button {
    border: none;
    width: 50%;
    background-color: ${(p) => p.theme.colors.darkBlue};
    color: #fff;
    border-radius: 8px;
    padding: 8px;
    font-weight: bold;

    &:hover {
      & > * {
        opacity: 0.8;
      }
      opacity: 0.8;
    }
  }
`;

type PopupProps = {
  show: boolean;
  headline: string;
  text: string;
  yesText?: string;
  noText?: string;
  onYes: Function;
  onNo: Function;
  onBackdropClick?: Function;
};

const Popup = ({
  show,
  headline,
  text,
  yesText = 'Ja',
  noText = 'Nein',
  onYes,
  onNo,
  onBackdropClick,
}: PopupProps) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <StyledBackDrop onClick={() => (onBackdropClick ? onBackdropClick() : false)} />
      <StyledPopupWrapper>
        <StyledContent>
          <p className="popup-head">{headline}</p>
          <p className="popup-text">{text}</p>
          <StyledButtonLayer>
            <button type="button" onClick={() => onYes()}>
              {yesText}
            </button>
            <button type="button" onClick={() => onNo()}>
              {noText}
            </button>
          </StyledButtonLayer>
        </StyledContent>
      </StyledPopupWrapper>
    </>
  );
};

Popup.defaultProps = {
  yesText: 'Ja',
  noText: 'Nein',
  onBackdropClick: () => false,
};

export default Popup;
