/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Headline from 'components/Headline';
import LoadingOverlay from 'components/LoadingOverlay';
import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const StyledLoginContainer = styled.div`
  height: 50vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const StyledLogin = styled.div`
  form {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 10px;

    input {
      padding: 10px;
      border-radius: 6px;
      border: 1px solid #cfcfcf;
    }

    button {
      &:disabled {
        background-color: #001722;
        border: 1px solid #001722;
        color: #979797;
        cursor: not-allowed;
        &:hover {
          opacity: 1;
        }
      }

      background-color: ${(p) => p.theme.colors.darkBlue};
      border-radius: 6px;
      border: 1px solid ${(p) => p.theme.colors.darkBlue};
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      padding: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const StyledError = styled.div`
  p {
    color: red;
    text-align: center;
  }
`;

const AdminLogin = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (email && email.length > 0 && password && password.length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [email, password]);

  const performLogin = (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    setHasError(false);

    const auth = getAuth();

    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            setLoading(false);
            console.log('Logged In User => ', user);
            history.push('/admin/list');
          })
          .catch((error: any) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setLoading(false);
            setHasError(true);
            setEmail('');
            setPassword('');
            console.log('Login Error => ', errorCode, errorMessage);
          });
      })
      .catch((error: any) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Login Error => ', errorCode, errorMessage);
      });
  };

  return (
    <StyledContainer>
      <Headline />
      <StyledLoginContainer>
        <StyledLogin>
          {hasError !== false ? (
            <StyledError>
              <p>Falsche Login Daten !</p>
            </StyledError>
          ) : null}

          <form onSubmit={(e) => performLogin(e)}>
            <input
              type="text"
              name="email"
              placeholder="E-Mail"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              type="password"
              name="password"
              value={password}
              placeholder="Passwort"
              onChange={(event) => setPassword(event.target.value)}
            />
            <button type="submit" disabled={!valid}>
              Anmelden
            </button>
          </form>
        </StyledLogin>
      </StyledLoginContainer>
      <LoadingOverlay show={loading} />
    </StyledContainer>
  );
};

export default AdminLogin;
