const colors = {
  transparent: 'transparent',
  background: '#dbdbdb',
  blue: '#8ecae6',
  green: '#219ebc',
  darkBlue: '#023047',
  yellow: '#ffb703',
  red: '#bc2143',
  tangerine: '#fb8500',
};

export default colors;
