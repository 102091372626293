/* eslint-disable @typescript-eslint/no-unused-vars */
import colors from 'constants/colors';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuid } from 'uuid';
import LoadingBar from './LoadingBar';

const StyledImage = styled.div`
  width: 120px;
  height: 60px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    /*
    width: 100px;
    height: 100px;
    */
  }
`;

type UploadItemProps = {
  file: any;
  onComplete: Function;
  onError: Function;
};

const UploadItem = ({ file, onComplete, onError }: UploadItemProps) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressColor, setProgressColor] = useState(colors.tangerine);

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const src = e.target.result;
      // const { type, size } = file;
      setImgSrc(src);
    };

    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    setProgress(0);
    const id: string = uuid();
    const storage = getStorage();
    const path = `images/${id}`;
    const thumbPath = `images/imagesSmall/${id}_600x600`;
    const storageRef = ref(storage, path);

    const uploadTask = uploadBytesResumable(storageRef, file);

    // const uploadTask = uploadBytesResumable(storageRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      (snapshot: any) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progressUpdate = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progressUpdate);
      },
      (error: any) => {
        // Handle unsuccessful uploads
        console.log('Error uploading image => ', error);
        setProgressColor(colors.red);
        setProgress(100);
        onError(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProgressColor(colors.green);
          onComplete(path, thumbPath, downloadURL);
        });
      }
    );
  }, [file]);

  if (imgSrc === null) {
    return null;
  }

  return (
    <>
      <StyledImage>
        <img src={imgSrc} alt="bla" />
      </StyledImage>

      <LoadingBar percent={progress} activeColor={progressColor} />
    </>
  );
};

const defaultProps = {};

UploadItem.defaultProps = defaultProps;

export default UploadItem;
